import {
  Box,
  Grid,
  ThemeProvider,
  createTheme,
  Container,
} from "@mui/material";
import { AnalyticEvents, toPascalCase } from "../../Utils/Helpers/helpers";
import { constantColors } from "../../Utils/Constants/constants";
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postOneForm, sendToZapier } from "../../Apis/Response";
import { InitialContext } from "../../Utils/Context/InitailContext";
import classes from "./Review.module.css";
import RadioInput from "../../Components/Radio/RadioButton";
import { MultipleChoice, OpenText } from "../../Components";
import { useSelector, useDispatch } from "react-redux";
import {
  clearRedux,
  setMultipleChoiceChanges,
  setOpenTextChanges,
  setRadioChanges,
} from "../../Store/Reducers/ResponseSlice";
import {
  LastProgress2,
  ProgressComponent,
} from "../../Components/ProgressWrapperComponent";
import HorizontalRadioReview from "../../Components/HorizontalRadio/HorizontalRadioReview";
import UseHeightHook from "../../Utils/Hooks/UseHeightHook";
import { theme } from "../../Utils/ThemeProvider/ThemeProvider";
import UseWidthHook from "../../Utils/Hooks/UseWidthHook";
import { CopylUrl } from "../../Utils/BaseUrl/BaseUrl";
const Review = () => {
  const history = useNavigate();
  const initial = useContext(InitialContext);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { data, isSuccess } = useSelector((state) => state?.initialResponse);
  const { userData } = useSelector((state) => state?.userData);

  const [reviewedAt, setReviewedAt] = useState(new Date());

  useEffect(() => {
    AnalyticEvents(
      JSON.stringify({
        reviewedAt: reviewedAt,
      }),
      "Assessment",
      "Review"
    );
  }, []);

  const checkError = () => {
    let ans = false;
    data.categories.forEach((cat) => {
      cat.questions.forEach((question) => {
        if (question.required && question.error) {
          ans = true;
          return ans;
        }
      });
    });
    return ans;
  };

  const handleTextValue = (e, questionIndex, categoryIndex, chars) => {
    let obj = {
      value: e.target.value,
      questionIndex,
      categoryIndex,
      chars,
      data,
    };
    dispatch(setOpenTextChanges(obj));
  };

  const handleRadioChange = (value, categoryIndex, questionIndex) => {
    let obj = {
      value: value,
      questionIndex,
      categoryIndex,
      data,
    };
    dispatch(setRadioChanges(obj));
  };

  const checkBoxChange = (value, questionIndex, categoryIndex) => {
    let obj = {
      value: value,
      questionIndex,
      categoryIndex,
      data,
    };
    dispatch(setMultipleChoiceChanges(obj));
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    let check = checkError();
    if (check) {
      initial.setSnacky({
        color: "error",
        message: "Please fill the required Fields ",
        open: true,
      });
      return;
    }
    const finalData = {
      form_id: data.id,
      categories_answers: data.categories,
      startup_id: userData.startup_id,
      user_id: userData.id,
    };
    setLoading(true);
    const result = await postOneForm(finalData);
    localStorage.setItem('form_key', result.key);
    if (result.error) {
      initial.setSnacky({
        color: "error",
        message: result.message,
        open: true,
      });
      setLoading(false);
    } else {
      const zapierData = {
        ...finalData,
        categories_answers: JSON.stringify({
          data: finalData.categories_answers
        }),
        link_to_result: `${CopylUrl}result/${result.key}`
      };
      await sendToZapier(zapierData);

      initial.setSnacky({
        color: "success",
        message: "Response Saved",
        open: true,
      });
      dispatch(clearRedux("clear"));
      const finishedAt = new Date();
      AnalyticEvents(
        JSON.stringify({
          startedAt: reviewedAt,
          finishedAt: finishedAt,
          timeSpent: Number(
            (finishedAt.getTime() - reviewedAt.getTime()) / 1000
          ),
        }),
        "Assessment",
        "Time to completion (full assessment)"
      );
      history("/congrats");
    }
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };
  const { categoryColors, categoryLightColors, categoryWidth } = useSelector(
    (state) => state?.constantData
  );
  const totalQuestions = data.categories.reduce(
    (total, category) => total + category.questions.length,
    0
  );
  let progressHeight = "0px";
  const windowWidth = UseWidthHook();
  let perQuestionWidth = 0;

  if (windowWidth < 768) {
    perQuestionWidth = Math.round(450 / totalQuestions) - 2;
    progressHeight = "20px";
  } else if (windowWidth < 1024) {
    perQuestionWidth = Math.round(550 / totalQuestions) - 1;
    progressHeight = "24px";
  } else if (windowWidth < 1280) {
    perQuestionWidth = Math.round(750 / totalQuestions) - 1;
    progressHeight = "28px";
  } else if (windowWidth < 1440) {
    perQuestionWidth = Math.round(750 / totalQuestions) - 1;
    progressHeight = "30px";
  } else {
    perQuestionWidth = Math.round(950 / totalQuestions) - 1;
    progressHeight = "32px";
  }

  return (
    <ThemeProvider theme={theme}>
      <form autoComplete="off" onSubmit={submitHandler}>
        <Grid
          container
          className={`${classes.flex_center} ${classes.text_padding}`}
        >
          <Grid item>
            {isSuccess && (
              <div>
                <div className={classes.font_style}>
                  {data.categories.map((stage, index) => (
                    <ProgressComponent
                      key={index}
                      backgroundColor={`${
                        categoryColors[stage.kind.toLowerCase()]
                      }`}
                      backgroundColorLight={`${
                        categoryLightColors[stage.kind.toLowerCase()]
                      }`}
                      height={progressHeight}
                    >
                      <div className={classes.progress_kind}>
                        {toPascalCase(stage.kind)}
                      </div>
                      {data.categories.length - 1 === index ? (
                        <LastProgress2
                          height={progressHeight}
                          backgroundColor={`${
                            categoryColors[stage.kind.toLowerCase()]
                          }`}
                          backgroundColorLight={`${
                            categoryLightColors[stage.kind.toLowerCase()]
                          }`}
                          width={stage.questions.length * perQuestionWidth}
                          className={`progress-bar ${
                            data.categories.length - 1 === index &&
                            "last-progress"
                          } `}
                          style={{
                            backgroundColor: `${
                              categoryLightColors[stage.kind.toLowerCase()]
                            }`,
                            width: `${
                              stage.questions.length * perQuestionWidth
                            }px`,
                            transform: "skew(-25deg)",
                            borderTopRightRadius: "5px",
                          }}
                        >
                          <div
                            className="progress imp"
                            style={{
                              backgroundColor: `${
                                categoryColors[stage.kind.toLowerCase()]
                              }`,
                              width: `${
                                categoryWidth[stage.kind.toLowerCase()]
                              }%`,
                              borderRadius: "0px",
                              borderTopRightRadius: "5px",
                              // borderRadius:'0px',
                            }}
                          ></div>
                        </LastProgress2>
                      ) : (
                        <div
                          className="progress-bar"
                          style={{
                            backgroundColor: `${
                              categoryLightColors[stage.kind.toLowerCase()]
                            }`,
                            width: `${
                              stage.questions.length * perQuestionWidth
                            }px`,
                            transform: "skew(-25deg)",
                          }}
                        >
                          <div
                            className="progress"
                            style={{
                              backgroundColor: `${
                                categoryColors[stage.kind.toLowerCase()]
                              }`,
                              width: `${
                                categoryWidth[stage.kind.toLowerCase()]
                              }%`,
                              borderRadius: "0px",
                            }}
                          ></div>
                        </div>
                      )}
                    </ProgressComponent>
                  ))}
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        {isSuccess &&
          data.categories.map((category, categoryIndex) => (
            <Grid container key={categoryIndex}>
              <Grid
                item
                sm={0.2}
                md={0.2}
                lg={0.2}
                lgm={0.2}
                xl={0.2}
                className={classes.side_bar}
                style={{
                  background: `${constantColors[categoryIndex]}`,
                }}
              ></Grid>
              <Grid item sm={1.5} md={2} lg={2} lgm={2} xl={2}>
                <h3 className={classes.side_heading}>
                  {toPascalCase(category.kind)}
                </h3>
              </Grid>
              <Grid item sm={1} md={0.2} lg={0.2} lgm={0.8} xl={0.2}></Grid>
              <Grid
                item
                sm={6.5}
                md={7.5}
                lg={7.5}
                lgm={6.3}
                xl={7.3}
                className={classes.whole_container}
              >
                <Grid container>
                  {category.questions.map((question, questionIndex) => (
                    <Grid
                      item
                      sm={12}
                      md={12}
                      lg={12}
                      lgm={12}
                      xl={12}
                      key={questionIndex}
                    >
                      <Box className={classes.first_section}>
                        <Grid
                          item
                          sm={1.5}
                          md={1.5}
                          lg={1.5}
                          lgm={1.5}
                          xl={1.5}
                        >
                          <p className={classes.number_text}>
                            {questionIndex + 1}/{category.questions.length}
                          </p>
                        </Grid>

                        <Grid
                          item
                          sm={10.5}
                          md={10.5}
                          lg={10.5}
                          lgm={10.5}
                          xl={10.5}
                        >
                          <p className={classes.review_question_text}>
                            {question.text}
                          </p>
                        </Grid>
                      </Box>
                      <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        className={`${question.is_horizontal && "d-block"}`}
                      >
                        {!question.is_horizontal && (
                          <Grid
                            item
                            sm={1.5}
                            md={1.5}
                            lg={1.5}
                            lgm={1.5}
                            xl={1.5}
                          ></Grid>
                        )}
                        <Grid
                          item
                          sm={!question.is_horizontal && 10.5}
                          md={!question.is_horizontal && 10.5}
                          lg={!question.is_horizontal && 10.5}
                          lgm={!question.is_horizontal && 10.5}
                          xl={!question.is_horizontal && 10.5}
                        >
                          {question.question_type === "SC" ? (
                            <>
                              {" "}
                              {question.is_horizontal ? (
                                <Box className={classes.radio_box}>
                                  <HorizontalRadioReview
                                    options={question.choices}
                                    questionIndex={questionIndex}
                                    categoryIndex={categoryIndex}
                                    onChange={handleRadioChange}
                                    label={question.text}
                                    answer={question.answers[0]}
                                    width={"26.5rem"}
                                  />{" "}
                                </Box>
                              ) : (
                                <Container disableGutters>
                                  <Box className={classes.radio_box}>
                                    <RadioInput
                                      isReview={true}
                                      options={question.choices}
                                      questionIndex={questionIndex}
                                      categoryIndex={categoryIndex}
                                      onChange={handleRadioChange}
                                      label={question.text}
                                      answer={question.answers[0]}
                                    />
                                  </Box>
                                </Container>
                              )}
                            </>
                          ) : question.question_type === "CB" ? (
                            <Container disableGutters>
                              <Box className={classes.cb_box}>
                                {data.categories[categoryIndex].questions[
                                  questionIndex
                                ].choices.map((val, valIndex) => (
                                  <MultipleChoice
                                    labelText={val.option}
                                    key={valIndex}
                                    val={val}
                                    answer={question.answers}
                                    questionIndex={questionIndex}
                                    categoryIndex={categoryIndex}
                                    checkBoxChange={checkBoxChange}
                                  />
                                ))}
                              </Box>
                            </Container>
                          ) : (
                            <Container disableGutters>
                              <Box className={classes.ot_box}>
                                <OpenText
                                  questionIndex={questionIndex}
                                  categoryIndex={categoryIndex}
                                  description={question.description}
                                  value={question}
                                  handleTextValue={handleTextValue}
                                  isReview={true}
                                />
                              </Box>
                            </Container>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          ))}
        <Grid item className={classes.base}>
          <button
            className={classes.submit_btn}
            type="submit"
            disabled={loading}
          >
            Submit
          </button>
        </Grid>
      </form>
    </ThemeProvider>
  );
};

export default Review;
